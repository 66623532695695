import React from "react";
import Header from "../components/header";

const TermsAndConditions = () => {

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="h-full flex flex-col justify-center lg:py-0 lg:pt-20">
        <div>
          <div className="cardContainerOuter max-w-xl mx-auto">
            <div className="cardContainerInner bg-gray-50">
              <div className="text-center mb-16">
                <h2>Terms and conditions</h2>
              </div>
              <a href="https://www.iubenda.com/terms-and-conditions/66998340" target="_blank" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Terms and Conditions">Terms and Conditions</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default TermsAndConditions;
